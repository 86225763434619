import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'components/layout';

import ActionPairItem from './ActionPairItem';

import s from './ActionPair.scss';

export default class ActionPair extends PureComponent {
  render() {
    const { actions } = this.props;
    return (
      <div className={s.actionPair}>
        <Container>
          <Row>
            {actions.map(action => (
              <div key={action.heading} className={s.actionPair__card}>
                <ActionPairItem {...action} />
              </div>
            ))}
          </Row>
        </Container>
        <hr className={s.actionPair__hr} />
      </div>
    )
  }
}