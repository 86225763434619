import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Clickable from 'components/clickable';
import Button from 'components/button';
import kebabCaseIcon from 'utils/kebab-case-icon';
import s from './ActionPairItem.scss';

export default class ActionPairItem extends PureComponent {

  static propTypes = {
    heading: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string,
  }

  render() {
    console.log('this.props', this.props);
    const { heading, link, linkText, icon } = this.props;

    return (
      <div className={s.actionPairItem}>
        {icon &&
          <div className={s.actionPairItem__icon}>
            <Icon id={kebabCaseIcon(icon)} />
          </div>
        }
        <h5 className={s.actionPairItem__heading}>{heading}</h5>
        <Button to={link} className={s.actionPairItem__link} color="borderDark">
          {linkText}
        </Button>
      </div>
    );
  }
}
