import React, { Component } from 'react';

import { Container, Row } from 'components/layout';
import Leader from 'components/leader';
import kebabCaseIcon from 'utils/kebab-case-icon';
import IconBlock from 'components/icon-block';

import s from './FeatureSet.scss';

class FeatureSet extends Component {
  render() {
    return (
      <div className={s.featureSet}>
        <div className={s.featureSet__introWrap}>
          <Container>
            <Leader
              className={s.featureSet__leader}
              centered
              heading={this.props.heading}
              text={this.props.subheading}
            />
          </Container>
        </div>
        <Container>
          <Row>
            {this.props.items && this.props.items.map(iconBlock => (
              <div className={s(
                s.featureSet__iconBlock,
                s.featureSet__iconBlockThird,
              )}>
                <IconBlock
                  heading={iconBlock.heading}
                  text={iconBlock.text}
                  icon={kebabCaseIcon(iconBlock.svgIcon)}
                />
              </div>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default FeatureSet;