import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';
import GreyArea from 'components/grey-area';
import { Container, Row, Segment } from 'components/layout';
import InstallersHero from '../../components/hero';
import FeatureSet from '../../components/feature-set';
import Testimonial from '../../components/testimonial';
import ActionPair from './components/action-pair';
import IconSet from '../../components/icon-set';
import IconBlock from '../../components/icon-block';
import TestimonialCarousel from './components/testimonial-carousel';
import SEO from 'components/seo';

class Installers extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('light');
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;
    return (
      <Fragment>
        <SEO pathname={pathname} article {...extractMeta(page)}/>
        <InstallersHero
          heading={page.heroHeading}
          text={page.heroText.text}
          poster={page.heroImage.file.url}
          theme="light"
        />
        <IconSet
          heading={page.featureSet.heading}
          text={page.featureSet.subheading}
          iconBlocks={page.featureSet.cards.map(card => ({
            heading: card.title,
            text: {text: card.body.text},
            icon: card.svgIcon,
            __typename: 'ContentfulIconBlock',
          }))}
          columnCount={3}
        />

        {page.testimonials && page.testimonials.length && (
          <LazyLoad once offset={500}>
            <TestimonialCarousel
              testimonials={page.testimonials}
            />
          </LazyLoad>
        )}
        <GreyArea>
          <ActionPair actions={page.actions} />
        </GreyArea>
      </Fragment>
    );
  }
}

export default withUIContext(Installers);
