import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { TweenLite, TimelineLite, Power4 } from 'gsap';

import Cite from 'components/cite';
import Image from 'components/image';
import { Row } from 'components/layout';
import ViewportEnter from 'components/viewport-enter';

import s from './TestimonialItem.scss';

const DURATION = 1000;
const EASE = Power4.easeInOut;

export default class TestimonialItem extends PureComponent {

  static propTypes = {
    logo: PropTypes.string,
    companyName: PropTypes.string,
    quote: PropTypes.string.isRequired,
    headshot: PropTypes.string,
    authorName: PropTypes.string,
    authorRole: PropTypes.string,
  }

  static defaultProps = {
    logo: '',
    companyName: '',
    headshot: '',
    authorName: '',
    authorRole: '',
  }

  animateEnter = () => {
    const t = new TimelineLite();

    t.addLabel('start')
      .to(
        this.item,
        (DURATION / 1000),
        { opacity: 1, ease: EASE },
      )
      .fromTo(
        this.logo,
        (DURATION / 1000),
        { opacity: 0, x: 100 },
        { opacity: 0.05, x: 0, ease: EASE },
        'start',
      )
      .fromTo(
        this.quote,
        (DURATION / 1000),
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, ease: EASE },
        'start',
      );
  }

  onEntering = () => {
    TweenLite.set(
      this.item,
      { opacity: 0 },
    );
  }

  onEnter = () => {
    this.animateEnter();
  }

  onEntered = () => {
    TweenLite.set(
      this.item,
      { zIndex: 1 },
    );
  }

  onExiting = () => {
    TweenLite.set(
      this.item,
      { zIndex: -1 },
    );
  }

  render() {

    const {
      logo,
      companyName,
      quote,
      headshot,
      authorName,
      authorRole,
      ...props
    } = this.props;

    return (
      <Transition
        {...props}
        timeout={DURATION}
        unmountOnExit
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExiting={this.onExiting}
      >
        <ViewportEnter onEnter={this.onEnter}>
          <div className={s('testimonialItem')} ref={(el) => { this.item = el; }}>
            <div className={s.testimonialItem__inner}>
              <div className={s.testimonialItem__logoWrap} ref={(el) => { this.logo = el; }}>
                {logo &&
                  <Image
                    className={s.testimonialItem__logo}
                    src={logo}
                    alt={companyName || ''}
                    transition="none"
                  />
                }
              </div>

              <div className={s.testimonialItem__container}>
                <div className={s.testimonialItem__content}>
                  <Row>
                    <div className={s.testimonialItem__quoteWrap}>
                      <blockquote
                        className={s.testimonialItem__blockquote}
                        ref={(el) => { this.quote = el; }}
                      >
                        <div className={s.testimonialItem__quote}>
                          {quote}
                        </div>
                      </blockquote>
                    </div>
                    <div className={s.testimonialItem__cite}>
                      <Cite headshot={headshot} name={authorName} role={authorRole} />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </ViewportEnter>
      </Transition>
    );
  }
}
