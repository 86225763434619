import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import TestimonialCarousel from './TestimonialCarousel';
import TestimonialItem from './TestimonialItem';
import TestimonialNavItem from './TestimonialNavItem';

export default class CarouselWrapper extends PureComponent {

  state = {
    activeSlide: 0,
  }

  onUpdate = (i) => {
    this.setState({
      activeSlide: i,
    });
  }

  static propTypes = {
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        companyLogo: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
          description: PropTypes.string,
        }),
        companyName: PropTypes.string,
        quote: PropTypes.shape({
          text: PropTypes.string,
        }).isRequired,
        authorName: PropTypes.string,
        authorRole: PropTypes.string,
        authorThumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        authorPortrait: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    ),
  }

  render() {
    return (
      <TestimonialCarousel
        activeSlide={this.state.activeSlide}
        onUpdate={this.onUpdate}
        nav={(
          <Fragment>
            {this.props.testimonials.map((item, i) => (
              <TestimonialNavItem
                key={item.quote.text}
                logo={_get(item.companyLogo, 'file.url')}
                companyName={item.companyName || _get(item.companyLogo, 'description')}
                index={i}
                active={this.state.activeSlide === i}
                onClick={() => { this.onUpdate(i); }}
              >
                {i}
              </TestimonialNavItem>
            ))}
          </Fragment>
      )}
      >
        {this.props.testimonials.map(item => (
          <TestimonialItem
            key={item.quote.text}
            logo={_get(item.companyLogo, 'file.url')}
            companyName={item.companyName || _get(item.companyLogo, 'description')}
            quote={item.quote.text}
            headshot={_get(item.authorThumbnail, 'file.url') || _get(item.authorPortrait, 'file.url')}
            authorName={item.authorName}
            authorRole={item.authorRole}
          />
        ))}

      </TestimonialCarousel>
    );
  }
}
