import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

import { Container } from 'components/layout';
import s from './TestimonialCarousel.scss';

export default class TestimonialCarousel extends Component {

  static propTypes = {
    children: PropTypes.node,
    activeSlide: PropTypes.number,
    nav: PropTypes.node,
  }

  render() {

    const { children, activeSlide, nav } = this.props;

    const slides = Children.toArray(children);

    return (
      <div className={s.testimonialCarousel}>
        <div className={s.testimonialCarousel__item}>
          <TransitionGroup>
            {slides[activeSlide]}
          </TransitionGroup>
        </div>

        <Container>
          <div className={s.testimonialCarousel__nav}>
            {nav}
          </div>
        </Container>
      </div>
    );
  }
}
