import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './TestimonialNavItem.scss';

export default class TestimonialNavItem extends PureComponent {

  static propTypes = {
    companyName: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    logo: PropTypes.string,
  }

  render() {
    const { companyName, logo, onClick, active } = this.props;

    const content = logo ? (
      <div className={s.testimonialNavItem__image}>
        <Image transition="none" src={logo} alt={companyName || ''} />
      </div>
    )
      : companyName;

    return (
      <button disabled={active} className={s('testimonialNavItem', { active })} onClick={onClick}>
        {content}
      </button>
    );
  }
}
