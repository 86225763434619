import { graphql } from 'gatsby';
import Developer from 'routes/installers/Installers';

export default Developer;

export const query = graphql`
  query Installers {
    page: contentfulPageInstallers {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroImage{
        ...image
      }
      testimonials {
        ...moduleList
      }
      actions {
        heading
        link
        linkText
        icon
      }
      featureSet {
        heading
        subheading
        cards {
          title
          svgIcon
          body {
            text: body
          }
        }
      }
    }
  }
`;
